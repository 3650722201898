var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog__wrapper" },
    [
      _c(
        "Dialog",
        {
          class: _vm.mobileView,
          attrs: { name: _vm.name, width: "728px" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Подтверждение операции")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", [
            _vm.isMultiple
              ? _c("div", { staticClass: "dialog__content" }, [
                  _vm._v(
                    " Вы действительно хотите удалить выделенные культуры?"
                  ),
                  _c("br"),
                  _c(
                    "ol",
                    _vm._l(_vm.getTableRowParams.names, function (item) {
                      return _c("li", { key: item }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    0
                  ),
                ])
              : _c("div", { staticClass: "dialog__content" }, [
                  _vm._v(
                    " Вы действительно хотите удалить выбранную культуру " +
                      _vm._s(_vm.getTableRowParams.name) +
                      "? "
                  ),
                ]),
            _c(
              "div",
              { staticClass: "alert__buttons" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "alert__buttons-button leftButtonMobile",
                    attrs: { type: "info", loading: _vm.getIsLoading },
                    on: {
                      click: function ($event) {
                        return _vm.handleRemoveCulture(false)
                      },
                    },
                  },
                  [_vm._v(" Нет ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "alert__buttons-button rightButtonMobile",
                    attrs: { type: "warning", loading: _vm.getIsLoading },
                    on: {
                      click: function ($event) {
                        return _vm.handleRemoveCulture(true)
                      },
                    },
                  },
                  [_vm._v(" Да ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }