<template>
  <div class="dialog__wrapper">
    <Dialog :name="name" width="728px" :class="mobileView">
      <template #title>
        <div>Подтверждение операции</div>
      </template>

      <div>
        <div v-if="isMultiple" class="dialog__content">
          Вы действительно хотите удалить выделенные культуры?<br />
          <ol>
            <li v-for="item in getTableRowParams.names" :key="item">
              {{ item }}
            </li>
          </ol>
        </div>

        <div v-else class="dialog__content">
          Вы действительно хотите удалить выбранную культуру
          {{ getTableRowParams.name }}?
        </div>

        <div class="alert__buttons">
          <el-button
            class="alert__buttons-button leftButtonMobile"
            type="info"
            :loading="getIsLoading"
            @click="handleRemoveCulture(false)"
          >
            Нет
          </el-button>

          <el-button
            class="alert__buttons-button rightButtonMobile"
            type="warning"
            :loading="getIsLoading"
            @click="handleRemoveCulture(true)"
          >
            Да
          </el-button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { CONTROL_TABLE_CULTURE_REMOVE_ROW } from '@/constants/dialogs'
import {
  DELETE_CULTURE,
  GET_IS_LOADING_CULTURE,
} from '@/views/control/store/actions'
import { GET_IS_MOBILE } from '@/store/actions'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'

export default {
  name: 'DialogRemoveTableRow',
  components: { Dialog },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    dialogFormVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { name: CONTROL_TABLE_CULTURE_REMOVE_ROW }
  },
  computed: {
    ...mapGetters({
      getIsLoading: GET_IS_LOADING_CULTURE,
      isMobile: GET_IS_MOBILE,
    }),
    getTableRowParams() {
      return this.getDialog(this.name).data || {}
    },
    isMultiple() {
      return this.getTableRowParams.isMultipleDelete
    },
    mobileView() {
      return this.isMobile ? 'alert-dialog' : 'remove-table-row'
    },
  },
  methods: {
    ...mapActions({ cultureDelete: DELETE_CULTURE }),
    handleRemoveCulture(isRemove = false) {
      if (isRemove) {
        const callback = this.closeDialog
        const ids = this.isMultiple
          ? this.getTableRowParams.ids
          : [this.getTableRowParams.id]

        this.cultureDelete({ ids, callback, params: this.params })
      } else {
        this.closeDialog()
      }
    },
    closeDialog() {
      this.setDialog({ name: this.name })
    },
  },
}
</script>

<style lang="sass">
.el-button + .el-button
  margin-left: 35px

.dialog__wrapper
  .remove-table-row
    .el-dialog__body
      .dialog__content
        margin-bottom: 120px
      .alert__buttons
        display: flex
        justify-content: flex-end
        &-button
          width: 60px

  .alert-dialog
    .el-dialog__header
      background-color: $color-red !important
    .el-dialog__body
      .dialog__content
        margin-bottom: 24px
        font-weight: 500
        font-size: 16px
        ol li::before
          color: #303133 !important
          font-weight: 400 !important
          font-size: 14px
      .alert__buttons
        display: flex
        justify-content: space-between
        &-button
          width: 48%
          height: 32px
          padding: 0 20px
          font-weight: 500 !important
        .leftButtonMobile
          border: $border-grey !important
          background-color: $backgroundGrayTwo !important
          color: $color-black
        .rightButtonMobile
          background-color: $color-red !important
</style>
